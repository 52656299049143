import { CDN2 } from '@/helpers/api';
import Image from 'next/image';

export default function MiniInfos() {
  return (
    <div className="flex flex-row justify-evenly mb-10">
      <div className="flex flex-col items-center">
        <Image
          src={CDN2() + '/staticmyapp/dollar.svg'}
          width={45}
          height={45}
          alt="dollar image"
        />
        <p className="text-[#515356] text-center mt-4 font-bold">
          The lowest price <br className="md:hidden flex" /> guaranteed
        </p>
      </div>
      <div className="flex flex-col items-center">
        <Image
          src={CDN2() + '/staticmyapp/fcancellation.svg'}
          width={45}
          height={45}
          alt="free cancellation image"
        />
        <p className="text-[#515356] text-center mt-4 font-bold">
          Free <br className="md:hidden flex" /> cancellation
        </p>
      </div>
      <div className="flex flex-col items-center">
        <Image
          src={CDN2() + '/staticmyapp/fshuttle.svg'}
          width={45}
          height={45}
          alt="free shuttle image"
        />
        <p className="text-[#515356] text-center mt-4 font-bold">
          Free shuttle <br className="md:hidden flex" /> service
        </p>
      </div>
    </div>
  );
}
