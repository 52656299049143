import { localReplaceAll } from '@/utils/tools';
import { absoluteServerPath } from './api';

function removeMultipĺeSlashes(str: string) {
  return localReplaceAll(str, '//', '/');
}
export function getDownloadLink(code: string): string {
  try {
    if (!code) return null;
    const link = removeMultipĺeSlashes(`/Reservation/Download/${code}`);
    return link;
  } catch (e) {
    const dd = `/Reservation/Download/${code}`;
    return null;
  }
}
export function getViewLink(code: string): string {
  if (!code) return null;
  const link = removeMultipĺeSlashes(`/Reservation/ViewPdf/${code}`);
  return link;
}

export function getImageCodeUrl(
  apiUrl: any,
  code: string,
  facility_barcode_type: Number
) {
  if (!code) return null;
  if (!facility_barcode_type) return null;
  const api = absoluteServerPath(apiUrl);
  const codeLink =
    facility_barcode_type === 2 // QrCode
      ? `${api}/api/imagecode/qr/${code}.jpg`
      : `${api}/api/imagecode/128b/${code}.jpg`;
  return removeMultipĺeSlashes(codeLink);
}

export function applyTextSubstitution(
  textData: string,
  parameters: { lowestPrice: number }
): string {
  const ret = textData?.replaceAll(
    '$LOWEST_PRICE$',
    parameters?.lowestPrice ? '$' + parameters.lowestPrice.toFixed(2) : ''
  );
  return ret;
}
